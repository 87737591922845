<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template #left>	
						<label class="p-text-bold p-mr-2" >Estado</label>				
						<MultiSelect v-model="selEstados" :options="lkpEstados" optionLabel="estado" display="chip" @change="fetchData"/>						
					</template>

					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="reclamos" class="p-datatable-sm" dataKey="id_reclamo" :paginator="true" :rows="20" :filters="filters"
							:globalFilterFields="['numero_ref', 'tipo', 'entidad']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[20,40,60]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loading" resizableColumns>
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Reclamos</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="numero_ref" filterField="numero_ref" header="#Ref" :sortable="true" headerStyle="width:100px">
					</Column>
					<Column field="fecha" header="Fecha" :sortable="true" headerStyle="width:125px">
					</Column>					
					<Column field="asunto" header="Asunto" :sortable="true" headerStyle="width: 30%">
					</Column>		
					<Column field="tipo"  header="Clasificación" :sortable="true" headerStyle="width: 175px">
					</Column>		
					<Column field="entidad"  header="Autor" :sortable="true" headerStyle="width: 25%">
					</Column>			
					<Column field="estado"  header="Estado" :sortable="true" headerStyle="min-width:25px">
						<template #body="slotProps">
							<span :class="'estado-badge status-' + slotProps.data.estado.toLowerCase()">{{slotProps.data.estado.toUpperCase()}}</span>
						</template>																							
					</Column>	
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-1" @click="edit(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
			</div>
			<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
				<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="entidad">Esta seguro que desea eliminar el reclamo seleccionado?<br><b>[{{ reclamo.numero_ref }}]</b>?</span>
				</div>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
					<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteRec" />
				</template>
			</Dialog>
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import MdsService from '../../service/mds.service';
import {FilterMatchMode} from 'primevue/api';

export default {
	data() {
		return {
			reclamos: null,
			complejo: this.$store.state.auth.currentAppCtx.complejo,
			deleteDialog: false,
			selEstados: [{id_reclamo_estado: 1, estado: "Nuevo"}, {id_reclamo_estado: 2, estado: "Abierto"}],
			lkpEstados: this.$store.state.auth.currentAppCtx.reclamos_estado,
			reclamo: {},		
			loading: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false
		}
	},
	components: {
		Loading
	},		
	mounted() {
		this.fetchData();
	},
	methods: {
		openNew() {
			this.$router.push({name: 'mdsreclamo', params: {id: ''}});	
		},
		edit(registro) {			
			this.$router.push({name: 'mdsreclamo', params: {id: registro.id_reclamo}});		
		},
		confirmDelete(registro) {
			this.reclamo = registro;
			this.deleteDialog = true;
		},
		deleteRec() {
			MdsService.eliminarInmueble(this.$store.state.auth.currentAppCtx, this.inmueble).then(() => {
					this.inmuebles = this.inmuebles.filter(val => val.id_inmueble !== this.inmueble.id_inmueble);
					this.deleteDialog = false;
					this.inmueble = {};
					this.$toast.add({severity:'success', summary: 'Exito', detail: 'El registro ha sido eliminado!', life: 3000})
			}
			).catch(
				data => {
					this.deleteDialog = false;
					this.product = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		fetchData() {
			let estados = [];
			this.selEstados.forEach(function(value) {
				var str = value.id_reclamo_estado;
				estados.push(str);
			});

			let id_inmueble = '';
			let id_entidad = '';
			let filtro = {
				id_complejo : this.complejo.id_complejo,
				filtro: estados.join(),
				id_inmueble: id_inmueble,
				id_entidad: id_entidad
			};

			this.loading = true;
			MdsService.getReclamos(this.$store.state.auth.currentAppCtx, filtro).then(
				data => {
					this.reclamos = data;
					this.loading = false;
				}
			).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)					
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.estado-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-cerrado {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-nuevo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-abierto {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-cancelado {
		background: #D3D3D3;
		color: #000000;
	}

}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
